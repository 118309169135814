import {
  reactive,
  toRefs,
  computed,
  watch,
  watchEffect,
  inject,
} from "vue"
import { useStore } from "vuex"
import { useRouter } from 'vue-router'

import { 
  getCartListApi,
  deleteCartGiftApi,
  batchDeleteCartGiftsApi,
} from "@/api/cart"
import { 
  defaultError,
} from '@/utils/common.js'
import { checkPoints, settlement } from '@/use/settlement'
import { SET_ORDER_GIFTS } from '@/store/mutation-types.js'

import ScrollLoadWrap from "@/components/ScrollLoadWrap"
import ProductModal from "@/components/ProductModal/ProductModal.vue"
import ErrorPopup from "@/components/ErrorPopup"

export default {
  name: 'ShoppingCart',
  components: {
    ScrollLoadWrap,
    ProductModal,
    ErrorPopup,
  },
  beforeRouteEnter(to, from, next) {
    // 从提交订单页返回，默认勾选已选择的礼品
    next(vm => {
      if(from.name !== 'SubmitOrder') {
        vm.$store.commit(SET_ORDER_GIFTS, [])
      }
    });
  },
  setup() {
    const state = reactive({
      list: [],
      isShowProductModal: false,
      currentGiftId: 0,
      currentShoppingCartId: 0,
      spec1: '',//当前编辑的产品的规格，sku用
      spec2: '',
      currentNum: 0,//当前编辑的产品的数量，sku用
      isSelectAll: false,
      isShowErrorPopup: false,
      errorTxt: '',
    })

    const store = useStore()
    const router = useRouter()
    const toast = inject('toast')
    const loading = inject('loading')

    const isFullScreen = computed(() => store.state.isFullScreen)
    
    const scrollLoad = () => {
      console.log('scroll bottom')
    }

    // 获取列表
    watchEffect( () => {
      if(!store.state.code) return
      getCartList()
    })
    async function getCartList() {
      loading.show()
      const res = await getCartListApi({
        activityCode: store.state.code,
      })
      loading.hide()

      if(res.code === 0) {
        state.list = res.data.map(x => {
          x.totalPrice = x.price * x.count
          x.specString = `${x.attributeRecord1Value} ${x.attributeRecord2Value}`
          x.outStock = x.count > x.stock

          if(x.outStock) {
            x.checked = false
          }
          else {
            // 从提交订单页返回，默认勾选已选择的礼品
            const orderGiftsIndex = store.state.orderGifts.findIndex(y => x.shoppingCartId === y.shoppingCartId)
            x.checked = orderGiftsIndex > -1
          }

          return x
        })
      }
      else {
        toast({
          message: res.message || defaultError,
        })
      }
    }

    const showProductModal = (index) => {
      const currentGift = state.list[index]
      state.currentGiftId = currentGift.giftId
      state.currentShoppingCartId = currentGift.shoppingCartId
      state.spec1 = currentGift.attributeRecord1Value
      state.spec2 = currentGift.attributeRecord2Value
      state.currentNum = currentGift.count

      state.isShowProductModal = true
    }

    const deleteGift = async (index) => {
      loading.show()
      const res = await deleteCartGiftApi({
        shoppingCarId: state.list[index].shoppingCartId,
      })
      loading.hide()

      if(res.code === 0) {
        state.list.splice(index, 1)
      }
      else {
        toast({
          message: res.message || defaultError,
        })
      }
    }

    // 勾选相关
    const selectGift = (index) => {
      state.list[index].checked = !state.list[index].checked
    }
    const selectAll = () => {
      if(state.list.filter(x => !x.outStock).length === 0) return

      state.isSelectAll = !state.isSelectAll
      state.list.forEach( x => {
        if(!x.outStock) {
          x.checked = state.isSelectAll
        }
      })
    }
    watch(
      () => state.list, 
      (newList) => {
        if(newList.filter(x => !x.outStock).length === 0) {
          // 没有 有库存的礼品
          state.isSelectAll = false
          return
        }
        state.isSelectAll = newList.every( x => {
          if(x.outStock) {
            return true
          }
          else {
            return x.checked
          }
        })
      },
      { deep: true }
    )
    // 合计积分
    const totalPrice = computed(() => {
      let price = 0
      state.list.map( x => {
        if(x.checked) {
          price += x.totalPrice
        }
      })

      return price
    })
    const settlementNum = computed(() => {
      let num = 0
      state.list.map( x => {
        if(x.checked) {
          num += x.count
        }
      })

      return num
    })

    const myPoints = computed(() => store.state.myPoints)
    const onSettlement = () => {
      const gifts = state.list.filter(x => x.checked)
      if(gifts.length === 0) {
        toast({
          duration: 2000,
          message: '请选择需要兑换的礼品',
        })
        return
      }

      const res = checkPoints(totalPrice.value, myPoints.value)
      if(!res.success) {
        state.isShowErrorPopup = true
        state.errorTxt = res.error
        return
      }

      const toNext = (routeObj) => {
        store.commit(SET_ORDER_GIFTS, gifts)
        router.push(routeObj)
      }

      settlement({
        toast,
        loading,
        toNext,
      })
    }

    const onDelete = async () => {
      const gifts = state.list.filter( x => x.checked )
      if(gifts.length === 0) {
        toast({
          duration: 2000,
          message: '请选择需要删除的礼品',
        })
        return
      }

      const requestData = gifts.map( x => {
        return {
          shoppingCarId: x.shoppingCartId
        }
      })
      loading.show()
      const res = await batchDeleteCartGiftsApi(requestData)
      loading.hide()

      if(res.code === 0) {
        getCartList()
      }
      else {
        toast({
          message: res.message || defaultError,
        })
      }
    }

    // 更新购物车后，同步修改页面上的数据
    const updateGift = (giftParams) => {
      const index = state.list.findIndex(x => x.shoppingCartId === giftParams.shoppingCartId)
      state.list[index] = {
        ...state.list[index],
        ...giftParams,
        totalPrice: giftParams.price * giftParams.count,
        specString: `${giftParams.attributeRecord1Value} ${giftParams.attributeRecord2Value || ''}`,
      }
    }

    return {
      ...toRefs(state),
      showProductModal,
      isFullScreen,
      scrollLoad,
      getCartList,
      deleteGift,
      selectGift,
      selectAll,
      totalPrice,
      settlementNum,
      onSettlement,
      onDelete,
      updateGift,
    }
  },
} 