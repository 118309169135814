<template>
  <div class="shoppingCart">
    <div class="shoppingCart-content">
      <div class="shoppingCart-main">
        <scroll-load-wrap @scroll-load="scrollLoad">
          <div
            v-for="(item, index) in list"
            :key="item.shoppingCartId"
            class="cart-item">
            <div class="cart-l">
              <div v-if="item.outStock" class="cart-status">无库存</div>
              <div 
                v-else
                @click="selectGift(index)"
                class="cart-select"
                :class="{active: item.checked}">
                <img src="@/assets/images/icon/hook.png" class="cart-hook">
              </div>
            </div>

            <div class="cart-r">
              <div class="productEditItem">
                <div class="item-imgBox">
                  <img :src="item.image" class="item-img">
                </div>

                <div class="item-content">
                  <div class="item-caption">
                    <div class="item-name">{{item.giftName}}</div>
                    <div class="item-params">{{item.specString}} X{{item.count}}</div>
                  </div>

                  <div class="item-bottom">
                    <div 
                      v-if="item.outStock"
                      @click="deleteGift(index)"
                      class="item-delete">删除</div>
                    <div 
                      v-else
                      @click="showProductModal(index)" 
                      class="editBtn-box">
                      <img src="@/assets/images/icon/edit.png" class="editBtn-img">
                      <div class="editBtn-txt">编辑</div>
                    </div>

                    <div class="item-points">
                      <div class="item-txt1">{{item.totalPrice}}</div>
                      <div class="item-txt2">积分</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

        </scroll-load-wrap>
      </div>
    </div>
    <div class="shoppingCart-summary" :class="{isFull: isFullScreen}">
      <div class="summary-selectBox">
        <div @click="selectAll" class="summary-select" :class="{active: isSelectAll}">
          <img src="@/assets/images/icon/hook.png" class="summary-hook">
        </div>
        <div @click="selectAll" class="summary-selectTxt">全选</div>
        <div @click="onDelete" class="summary-delete">删除</div>
      </div>

      <div class="summary-content">
        <div class="summary-txt1">合计：</div>
        <div class="summary-points">{{totalPrice}}</div>
        <div class="summary-txt2">积分</div>
        <div @click="onSettlement" class="summary-btn">结算({{settlementNum}})</div>
      </div>
    </div>
  </div>

  <ProductModal 
    v-model:isVisible="isShowProductModal"
    :gift-id="currentGiftId"
    :shopping-cart-id="currentShoppingCartId"
    :spec1="spec1"
    :spec2="spec2"
    :default-num="currentNum"
    type="shoppingCart"
    @update-gift="updateGift" />

  <ErrorPopup
    v-model:isVisible="isShowErrorPopup"
    :txt="errorTxt" />
</template>

<script src='./ShoppingCart.js'></script>

<style scoped lang="scss" src="./ShoppingCart.scss"></style>